
import Vue, { PropType } from "vue";

import { DialogCloseType, DialogI } from "@/types/dialog";
import Btn from "@/components/v2/base/Btn.vue";
import Header from "@/components/v2/base/Header.vue";

export default Vue.extend({
  name: "Dialog",
  components: {
    Header,
    Btn,
  },
  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
  computed: {
    minHeight(): string {
      return this.dialog.config.minContentHeight || "";
    },
  },
  methods: {
    close() {
      this.dialog.close({ type: DialogCloseType.CLOSED, payload: {} });
    },
    submit() {
      this.$emit("submit");
    },
  },
});
