
import Vue, { PropType } from "vue";
import { DialogCloseType, DialogI } from "@/types/dialog";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import BasketGiftCardForm from "@/components/v2/subscription/BasketGiftCardForm.vue";
import { GiftCardProduct } from "@/types/common";
import { LangCode, BaseGiftCard, ProductCode } from "@prestonly/preston-common";
import { VueWithValidator } from "@/utils/validators";

export default Vue.extend({
  name: "GiftCardAddDialog",
  components: {
    BaseDialog,
    BasketGiftCardForm,
  },
  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
  data: () => ({
    selectedFile: null as File | null,
    valid: false,
    fields: {
      plan: null as GiftCardProduct["plan"] | null,
      languages: [] as LangCode[],
      inscription: "",
    } as GiftCardProduct,
    form: null,
  }),
  methods: {
    mapProductToGiftCard(product: GiftCardProduct): BaseGiftCard {
      const sourceLang = product.plan.plan.sourceLang;
      const productCodes = product.languages.map((lang): ProductCode => `${product.plan.value}:${sourceLang}:${lang}`);
      return {
        productCodes,
        inscription: product.inscription || "",
        sendAt: "",
        sendToEmailAddress: "",
      };
    },
    async submitForm() {
      const form = this.$refs.form as InstanceType<typeof VueWithValidator>;
      await form.validate();
      if (!this.valid) {
        return;
      }
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: this.mapProductToGiftCard(this.fields),
      });
    },
  },
});
